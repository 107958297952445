export enum FilterParamNames {
  type = 'type',

  tagIdAnd = 'tagIdAnd',
  tagIdOr = 'tagIdOr',
  tagIdNot = 'tagIdNot',

  /** Imported keywords */
  importedKeywordAnd = 'impKeyAnd',
  importedKeywordOr = 'impKeyOr',
  importedKeywordNot = 'importedKeywordNot',

  uploaderId = 'uploaderId',

  cameraMake = 'camMake',
  cameraModel = 'camModel',

  creator = 'cr',

  copyright = 'cop',

  color = 'color',
  ext = 'ext',

  source = 'source',

  // location filters
  // these filters are independent - user can select any country, any city and any state, even if selected city does not belong to selected country
  // in the future we will probably make one "Location" filter, which will be a combination of country, city and state
  country = 'ct',
  city = 'city',
  state = 'st',

  videoFrameRate = 'vfr',
  videoAspectRatio = 'var',
  audioCoding = 'ac',
  audioSampleRate = 'asr',

  bookmarked = 'bookmarked',
  isOnBoards = 'isOnBoards',
  hasOpenComments = 'hasOpenComments',
  hasMultipleVersions = 'hasMultipleVersions',

  cfIdAnd = 'cfIdAnd-',
  cfIdOr = 'cfIdOr-',
  cfIdNot = 'cfIdNot-',

  recordedDateStart = 'recordedDateStart',
  recordedDateEnd = 'recordedDateEnd',

  uploadedDateStart = 'uploadedDateStart',
  uploadedDateEnd = 'uploadedDateEnd',

  modifiedDateStart = 'modifiedDateStart',
  modifiedDateEnd = 'modifiedDateEnd',

  takenDateStart = 'tds',
  takenDateEnd = 'tde',

  libraryId = 'libraryId',
  boardId = 'boardId',
  onlyInLibraries = 'onlyInLibraries',
}

export const FilterParamNamesArray = Object.values(FilterParamNames);

export type QueryType = Partial<Record<QueryParamNames, string | string[] | number | boolean | null>>;

export enum QueryParamNames {
  Search = 'q',
  workspaceId = 'wsid',
  isFlattenedView = 'isFlatView',
  /** tab in asset modal, should be one of
   * @link ASSET_MODAL_TABS
   */
  tab = 'tab',

  /** Determine whether to open versions panel, should be true or false */
  versions = 'versions',

  /**
   * This is active timestamp of video asset - time on which video is paused (used e.g. to show annotations)
   */
  timestamp = 'ts',

  /**
   * Array of active discussions ids (used in asset modal) - there may be more than one if clicked on timestamp marker
   */
  discussionId = 'dId',
  /** plan name */
  planId = 'planId',
  /** coupon code */
  couponCode = 'couponCode',
  /** boolean for whether password was reset */
  passwordReset = 'passwordReset',
  redirect = 'redirect',
  existingEmail = 'existingEmail',
  /** indicates 'success' or 'failure' of slack intetgration auth flow */
  slackAuthStatus = 'slackAuthStatus',
  /** id of asset to open in Finder, used in open-desktop-file page */
  assetId = 'assetId',
  /** id of clip to open in Finder, used in open-desktop-file page */
  clipId = 'clipId',
  /** id of board to open in Finder, used in open-desktop-file page */
  boardId = 'boardId',
  /** controls visibility of notifications panel */
  notificationsPanel = 'nPanel',
  /** id of currently selected captured text */
  capTextId = 'capTextId',
  /**
   * used for pdf pages
   */
  page = 'page',
}

export const CustomFieldFilters = [FilterParamNames.cfIdAnd, FilterParamNames.cfIdOr, FilterParamNames.cfIdNot];

export type FiltersWithSingleValueType =
  | FilterParamNames.recordedDateStart
  | FilterParamNames.recordedDateEnd
  | FilterParamNames.uploadedDateStart
  | FilterParamNames.uploadedDateEnd
  | FilterParamNames.modifiedDateStart
  | FilterParamNames.modifiedDateEnd
  | FilterParamNames.bookmarked
  | FilterParamNames.isOnBoards
  | FilterParamNames.hasOpenComments
  | FilterParamNames.hasMultipleVersions
  | FilterParamNames.libraryId
  | FilterParamNames.onlyInLibraries
  | FilterParamNames.boardId
  | FilterParamNames.takenDateStart
  | FilterParamNames.takenDateEnd
  | FilterParamNames.cameraMake;

export const FiltersWithSingleValue: FiltersWithSingleValueType[] = [
  FilterParamNames.recordedDateStart,
  FilterParamNames.recordedDateEnd,
  FilterParamNames.uploadedDateStart,
  FilterParamNames.uploadedDateEnd,
  FilterParamNames.modifiedDateStart,
  FilterParamNames.modifiedDateEnd,
  FilterParamNames.bookmarked,
  FilterParamNames.isOnBoards,
  FilterParamNames.onlyInLibraries,
  FilterParamNames.hasOpenComments,
  FilterParamNames.hasMultipleVersions,
  FilterParamNames.libraryId,
  FilterParamNames.boardId,
  FilterParamNames.takenDateStart,
  FilterParamNames.takenDateEnd,
  FilterParamNames.cameraMake,
];

export const FiltersWithBooleanValue: FilterParamNames[] = [
  FilterParamNames.bookmarked,
  FilterParamNames.isOnBoards,
  FilterParamNames.hasOpenComments,
  FilterParamNames.hasMultipleVersions,
];

export type FiltersWithArrayValueType =
  | FilterParamNames.tagIdOr
  | FilterParamNames.tagIdAnd
  | FilterParamNames.tagIdNot
  | FilterParamNames.source
  | FilterParamNames.type
  | FilterParamNames.color
  | FilterParamNames.uploaderId
  | FilterParamNames.ext
  | FilterParamNames.country
  | FilterParamNames.city
  | FilterParamNames.state
  | FilterParamNames.importedKeywordAnd
  | FilterParamNames.importedKeywordOr
  | FilterParamNames.importedKeywordNot
  | FilterParamNames.copyright
  | FilterParamNames.creator
  | FilterParamNames.cameraModel
  | FilterParamNames.videoFrameRate
  | FilterParamNames.videoAspectRatio
  | FilterParamNames.audioCoding
  | FilterParamNames.audioSampleRate;

export const FiltersWithArrayValue: FiltersWithArrayValueType[] = [
  FilterParamNames.tagIdOr,
  FilterParamNames.tagIdAnd,
  FilterParamNames.tagIdNot,
  FilterParamNames.source,
  FilterParamNames.type,
  FilterParamNames.color,
  FilterParamNames.uploaderId,
  FilterParamNames.ext,
  FilterParamNames.importedKeywordAnd,
  FilterParamNames.importedKeywordOr,
  FilterParamNames.importedKeywordNot,
  FilterParamNames.copyright,
  FilterParamNames.creator,
  FilterParamNames.cameraModel,
  FilterParamNames.videoFrameRate,
  FilterParamNames.country,
  FilterParamNames.city,
  FilterParamNames.state,
  FilterParamNames.videoAspectRatio,
  FilterParamNames.audioCoding,
  FilterParamNames.audioSampleRate,
];
