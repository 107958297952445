import { Auth } from '@air/api';
import { useQuery } from '@tanstack/react-query';

import { ONE_DAY } from '~/constants/unitsOfTime';

export const CURRENT_SESSION = 'CURRENT_SESSION';

export const getCurrentSessionKey = () => [CURRENT_SESSION];

export const useGetCurrentUserSession = () => {
  return useQuery(getCurrentSessionKey(), Auth.getCurrentSession, { staleTime: ONE_DAY });
};
