import { SyntheticEvent, useState } from 'react';
import Imgix, { SharedImigixAndSourceProps } from 'react-imgix';

export interface ImgixImageParams extends SharedImigixAndSourceProps {
  /** The alt text for the image - this should be passed in for accessibility purposes */
  alt: string;
  /** This is the backup URL of the image in the event that Imgix is down */
  fallbackUrl: string;
  /** This callback fires if both the imgix service and the fallback URL fail to render the image url passed in  */
  onError?: (error: SyntheticEvent<HTMLImageElement, Event>) => void;
  /** Use this callback to fire when either the Imgix or fallback img loads successfully */
  onLoad?: () => void;
  /** Use to disable drag which is true by default- needed for image annotations */
  draggable?: boolean;
}

/**
 * This component is a wrapper around the react-imgix component and it's purpose is to allow a fallback url (`fallbackUrl`) to load in the event that the Imgix service is down. You'd be surprised how often this happens.
 * All props are spread onto the react-imgix component with the exception of fallbackUrl and onError.
 */
export const ImgixImage = ({ fallbackUrl, onError, onLoad, alt, draggable = true, ...rest }: ImgixImageParams) => {
  const [error, setError] = useState(false);

  return !!error ? (
    // eslint-disable-next-line @next/next/no-img-element
    <img src={fallbackUrl} alt={alt} onError={onError} onLoad={onLoad} draggable={draggable} />
  ) : (
    <Imgix
      {...rest}
      htmlAttributes={{
        alt,
        draggable,
        ...(rest.htmlAttributes || {}),
        onError: (error: any) => {
          setError(!!error);
        },
        onLoad,
      }}
    />
  );
};
