import { FilterTrackingType } from '@air/analytics';

import { CustomFieldFilterType, FilterType } from '~/components/FiltersMenu/types';
import {
  DateFilter,
  FiltersState,
  initialFiltersState,
  OtherFiltersType,
  SelectedFilter,
  SelectedFilterValueObject,
} from '~/store/filters/types';

export const resetFilterStateToInitialState = (
  state: FiltersState,
  filter: FilterType,
  customFields: CustomFieldFilterType[],
) => {
  switch (filter) {
    case 'type':
      state.types = initialFiltersState.types;
      break;
    case 'uploader':
      state.uploaders = initialFiltersState.uploaders;
      break;
    case 'color':
      state.colors = initialFiltersState.colors;
      break;
    case 'customField':
      state.customFields = state.customFields.filter((cfFilter) => !customFields.some((cf) => cfFilter.id === cf.id));
      break;
    case 'tags':
      state.tags = initialFiltersState.tags;
      break;
    case 'extension':
      state.extensions = initialFiltersState.extensions;
      break;
    case 'source':
      state.sources = initialFiltersState.sources;
      break;
    case 'other':
      state.others = initialFiltersState.others;
      break;
    case 'dateCreated':
      state.createdDate = initialFiltersState.createdDate;
      break;
    case 'dateUploaded':
      state.uploadedDate = initialFiltersState.uploadedDate;
      break;
    case 'dateModified':
      state.modifiedDate = initialFiltersState.modifiedDate;
      break;
    case 'videoFrameRate':
      state.videoFrameRates = initialFiltersState.videoFrameRates;
      break;
    case 'dateTaken':
      state.takenDate = initialFiltersState.takenDate;
      break;
    case 'importedKeywords':
      state.importedKeywords = initialFiltersState.importedKeywords;
      break;
    case 'copyright':
      state.copyrights = initialFiltersState.copyrights;
      break;
    case 'creator':
      state.creators = initialFiltersState.creators;
      break;
    case 'camera':
      state.camera = initialFiltersState.camera;
      break;
    case 'country':
      state.countries = initialFiltersState.countries;
      break;
    case 'city':
      state.cities = initialFiltersState.cities;
      break;
    case 'state':
      state.states = initialFiltersState.states;
      break;
    case 'videoAspectRatio':
      state.videoAspectRatios = initialFiltersState.videoAspectRatios;
      break;
    case 'audioCoding':
      state.audioCodings = initialFiltersState.audioCodings;
      break;
    case 'audioSampleRate':
      state.audioSampleRates = initialFiltersState.audioSampleRates;
      break;
  }
};

export const filterTypeToTrackingType = (filter: FilterType | OtherFiltersType): FilterTrackingType[] => {
  switch (filter) {
    case 'date':
    case 'location':
      return [];
    case 'state':
      return ['state'];
    case 'type':
      return ['type'];
    case 'uploader':
      return ['uploader'];
    case 'color':
      return ['color'];
    case 'customField':
      return ['custom-fields'];
    case 'tags':
      return ['tags'];
    case 'extension':
      return ['extension'];
    case 'source':
      return ['source'];
    case 'dateCreated':
      return ['date-created'];
    case 'dateUploaded':
      return ['date-uploaded'];
    case 'dateModified':
      return ['date-modified'];
    case 'is-favorited':
      return ['favorited'];
    case 'is-on-boards':
      return ['is-on-boards'];
    case 'has-open-comments':
      return ['has-open-comments'];
    case 'has-versions':
      return ['has-versions'];
    case 'copyright':
      return ['copyright'];
    case 'other':
      return ['other'];
    case 'dateTaken':
      return ['date-taken'];
    case 'importedKeywords':
      return ['imported-keywords'];
    case 'creator':
      return ['creator'];
    case 'camera':
      return ['camera'];
    case 'videoFrameRate':
      return ['video-frame-rate'];
    case 'videoAspectRatio':
      return ['video-aspect-ratios'];
    case 'audioCoding':
      return ['audio-coding'];
    case 'country':
      return ['country'];
    case 'city':
      return ['city'];
    case 'audioSampleRate':
      return ['audio-sample-rate'];
    default:
      return [];
  }
};

export const hasDateFilterSelectedValue = (dateFilter: DateFilter) => {
  if (dateFilter.type === 'custom-range') return !!dateFilter.startDate || !!dateFilter.endDate;
  return dateFilter.type !== 'all-time';
};

export const shouldKeepFilter = (type: SelectedFilter['type'], state: FiltersState) => {
  switch (type) {
    case 'type': {
      return state.types.length > 0;
    }
    case 'uploader': {
      return state.uploaders.length > 0;
    }
    case 'color': {
      return state.colors.length > 0;
    }
    case 'importedKeywords': {
      return state.importedKeywords.keywords.length > 0;
    }
    case 'customField': {
      return state.customFields.some((f) => f.values.length > 0);
    }
    case 'tags': {
      return state.tags.tags.length > 0;
    }
    case 'extension': {
      return state.extensions.length > 0;
    }
    case 'source': {
      return state.sources.length > 0;
    }
    case 'dateCreated': {
      return hasDateFilterSelectedValue(state.createdDate);
    }
    case 'dateModified': {
      return hasDateFilterSelectedValue(state.modifiedDate);
    }
    case 'dateUploaded': {
      return hasDateFilterSelectedValue(state.uploadedDate);
    }
    case 'dateTaken': {
      return hasDateFilterSelectedValue(state.takenDate);
    }
    case 'other': {
      return state.others.length > 0;
    }
    case 'copyright':
      return state.copyrights.length > 0;
    case 'date':
    case 'location':
    case 'audioVisual': {
      return true;
    }
    case 'country': {
      return state.countries.length > 0;
    }
    case 'creator': {
      return state.creators.length > 0;
    }
    case 'camera': {
      return !!state.camera.make || state.camera.models.length > 0;
    }
    case 'city': {
      return state.cities.length > 0;
    }
    case 'state': {
      return state.states.length > 0;
    }
    case 'videoFrameRate': {
      return state.videoFrameRates.length > 0;
    }
    case 'videoAspectRatio': {
      return state.videoAspectRatios.length > 0;
    }
    case 'audioCoding': {
      return state.audioCodings.length > 0;
    }
    case 'audioSampleRate': {
      return state.audioSampleRates.length > 0;
    }
    default: {
      const _exhaustiveCheck: never = type; // Ensure the switch block is exhaustive
      return !!_exhaustiveCheck; // Just for fixing unused var ts error
    }
  }
};

export const getSelectedFilterValue = (
  type: SelectedFilter['type'],
  state: FiltersState,
): SelectedFilterValueObject | undefined => {
  switch (type) {
    case 'type': {
      return { key: 'types', value: state.types };
    }
    case 'uploader': {
      return { key: 'uploaders', value: state.uploaders };
    }
    case 'color': {
      return { key: 'colors', value: state.colors };
    }
    case 'customField': {
      return { key: 'customFields', value: state.customFields };
    }
    case 'tags': {
      return { key: 'tags', value: state.tags };
    }
    case 'extension': {
      return { key: 'extensions', value: state.extensions };
    }
    case 'source': {
      return { key: 'sources', value: state.sources };
    }
    case 'dateCreated': {
      return { key: 'createdDate', value: state.createdDate };
    }
    case 'dateModified': {
      return { key: 'modifiedDate', value: state.modifiedDate };
    }
    case 'dateUploaded': {
      return { key: 'uploadedDate', value: state.uploadedDate };
    }
    case 'dateTaken': {
      return { key: 'takenDate', value: state.takenDate };
    }
    case 'other': {
      return { key: 'others', value: state.others };
    }
    case 'importedKeywords': {
      return { key: 'importedKeywords', value: state.importedKeywords };
    }
    case 'copyright':
      return { key: 'copyrights', value: state.copyrights };
    case 'creator': {
      return { key: 'creators', value: state.creators };
    }
    case 'camera': {
      return {
        key: 'camera',
        value: {
          make: state.camera.make,
          models: state.camera.models,
        },
      };
    }
    case 'videoFrameRate': {
      return { key: 'videoFrameRates', value: state.videoFrameRates };
    }
    case 'country': {
      return { key: 'countries', value: state.countries };
    }
    case 'city': {
      return { key: 'cities', value: state.cities };
    }
    case 'state': {
      return { key: 'states', value: state.states };
    }
    case 'videoAspectRatio': {
      return { key: 'videoAspectRatios', value: state.videoAspectRatios };
    }
    case 'audioCoding': {
      return { key: 'audioCodings', value: state.audioCodings };
    }
    case 'audioSampleRate': {
      return { key: 'audioSampleRates', value: state.audioSampleRates };
    }
  }
};
