import { ClipAndBoardListItem } from '@air/api/types';
import { createAction } from '@reduxjs/toolkit';

import { DndSortableKanbanItemData } from '~/types/DndKit';
import { KanbanColumnsWithInitialData } from '~/types/KanbanData';

export const kanbanItemDragStartAction = createAction('KANBAN_ITEM_DRAG_START');

export const resetKanbanManagerAction = createAction('RESET_KANBAN_MANAGER');

export const kanbanItemDragCancelAction = createAction('KANBAN_ITEM_DRAG_CANCEL');

export const setGroupedItemsResponseInKanbanManagerAction = createAction<{
  columnsWithInitialData: KanbanColumnsWithInitialData;
}>('SET_GROUPED_ITEMS_RESPONSE_IN_KANBAN_MANAGER');

export const updateKanbanColumnWithNewDataAction = createAction<{
  columnsToUpdate: Record<string, ClipAndBoardListItem[]>;
  shouldSkipSWRSync?: boolean;
}>('UPDATE_KANBAN_COLUMN_WITH_NEW_DATA');

export const kanbanItemDragEndAction = createAction<{
  updatedColumnItems: Record<string, DndSortableKanbanItemData[]>;
}>('SET_UPDATED_KANBAN_COLUMN_ITEMS');

export const kanbanItemPositionSuccessAction = createAction('KANBAN_ITEM_POSITION_SUCCESS');

export const kanbanColumnFetcherSyncedAction = createAction<{ kanbanColumnId: string }>('KANBAN_COLUMN_FETCHER_SYNCED');

export const removeKanbanItemsAction = createAction<{ itemIds: string[] }>('REMOVE_KANBAN_ITEMS');
