import { ConfigurableView, SortDirection, ViewFieldSupport, ViewTypeName, VisibleColumnType } from '@air/api/types';
import { createSelector } from '@reduxjs/toolkit';
import { isUndefined } from 'lodash';
import isEqual from 'react-fast-compare';

import { defaultKanbanSort } from '~/components/KanbanView/shared/kanbanConstants';
import { getConfigFieldLabel } from '~/constants/SortFieldsMap';
import { getDefaultSortDirection } from '~/store/configViews/utils';
import { AirState } from '~/store/types';

import { ConfigViewOption } from './types';

const baseConfigViewsSelector = ({ configViews }: AirState) => configViews;

export const currentConfigurableViewSelector = createSelector(
  baseConfigViewsSelector,
  (configViews) => configViews.currentConfigurableView,
);

export const isKanbanViewSelector = createSelector(
  currentConfigurableViewSelector,
  (currentView) => !!currentView && currentView.viewType.name === ViewTypeName.kanban,
);

const configurableViewFieldsSelector = createSelector(
  baseConfigViewsSelector,
  (configView) => configView.configurableViewFields,
);

export const visibleFieldsSelector = createSelector(
  currentConfigurableViewSelector,
  (currentConfigurableView) => currentConfigurableView?.visibleFields,
);

export const currentViewTypeNameSelector = createSelector(
  baseConfigViewsSelector,
  (configView) => configView.currentConfigurableView?.viewType.name,
);

export const savedConfigurableViewsSelector = createSelector(
  baseConfigViewsSelector,
  (configViews) => configViews.savedConfigurableViews,
);

export const availableViewTypeNamesSelector = createSelector(savedConfigurableViewsSelector, (savedConfigurableViews) =>
  savedConfigurableViews.map((cv) => cv.viewType.name),
);

/**
 * This has an optional name. May break with custom fields
 * We also hard-code the first value in the array
 *
 * The name and direction selectors are more optimal to reduce
 * rerenders
 */
export const currentSortFieldSelector = createSelector(
  currentConfigurableViewSelector,
  (currentConfigurableView) => currentConfigurableView?.sortFields[0],
);
export const isSortFieldKanbanDefaultSelector = createSelector(currentSortFieldSelector, (sortField) => {
  if (isUndefined(sortField)) return true;
  // don't compare the two, since sometimes sortField has `customFieldId` set
  return isEqual({ name: sortField.name, direction: sortField.direction }, defaultKanbanSort);
});
type BaseConfigField = { name?: string; customFieldId?: string };
const isSameField = (fieldA: BaseConfigField, fieldB: BaseConfigField) => {
  if (fieldA.customFieldId && fieldB.customFieldId) {
    return fieldA.customFieldId === fieldB.customFieldId;
  }
  if (fieldA.name && fieldB.name) {
    return fieldA.name === fieldB.name;
  }
  return false;
};
/**
 * This provides ConfigViewOption, which is an object that combines the ViewField option
 * and the present "saved" or temporarily overriden state of the Configurable View
 */
export const configViewOptionsSelector = createSelector(
  [configurableViewFieldsSelector, visibleFieldsSelector, currentSortFieldSelector],
  (configViewFields, visibleFields = [], currentSortField) =>
    configViewFields.map((viewField) => {
      const isVisible = visibleFields.some((field) => isSameField(field, viewField));

      const configViewOption: ConfigViewOption = {
        fieldName: viewField.name,
        isCurrentSortedOption: currentSortField?.name === viewField.name,
        isVisible,
        supports: viewField.supports,
        label: getConfigFieldLabel(viewField.name) || viewField.name,
        sortDirection:
          currentSortField?.name === viewField.name
            ? currentSortField.direction
            : getDefaultSortDirection(viewField.name),
        isCustomField: viewField.customFieldId !== undefined,
        customFieldId: viewField.customFieldId,
      };

      return configViewOption;
    }),
);

export const visibleCustomFieldsViewOptionsSelector = createSelector(configViewOptionsSelector, (configViewOptions) =>
  configViewOptions.filter((option) => option.isCustomField && option.isVisible),
);

export const visibleCustomFieldsLengthSelector = createSelector(
  currentConfigurableViewSelector,
  (configView) => configView?.visibleFields.filter((f) => !!f.customFieldId).length || 0,
);

const allVisibilityConfigViewOptionsSelector = createSelector(configViewOptionsSelector, (configViewOptions) =>
  configViewOptions.filter(
    // TODO: dimension is replaced by resolution and we do not want to show this column - it will be finally removed on backend
    (opt) => opt.fieldName !== 'dimension' && opt.supports.includes(ViewFieldSupport.visibility),
  ),
);
export const configViewVisiblityOptionsSelector = createSelector(allVisibilityConfigViewOptionsSelector, (options) =>
  options.filter((i) => !i.isCustomField),
);
export const customFieldVisibilityOptionsSelector = createSelector(allVisibilityConfigViewOptionsSelector, (options) =>
  options.filter((i) => i.isCustomField),
);

export const visibleConfigViewOptionsSelector = createSelector(configViewOptionsSelector, (configViewOptions) =>
  // TODO: dimension is replaced by resolution and we do not want to show this column - it will be finally removed on backend
  configViewOptions.filter((opt) => opt.isVisible && opt.fieldName !== 'dimension'),
);
export const sortingConfigViewOptionsSelector = createSelector(configViewOptionsSelector, (configViewOptions) =>
  configViewOptions.filter((opt) => opt.supports.includes(ViewFieldSupport.sort)),
);

export const currentSortFieldNameSelector = createSelector(
  currentSortFieldSelector,
  (currentSortField) => currentSortField?.name || 'dateModified',
);
export const currentSortFieldDirectionSelector = createSelector(
  currentSortFieldSelector,
  (currentSortField) => currentSortField?.direction || SortDirection.asc,
);

export const isTitleAndMetadataVisibleSelector = createSelector(visibleFieldsSelector, (visibleFields = []) =>
  visibleFields.some((field) => field.name === 'titleAndMetadata'),
);
export const activeTableColumnsSelector = createSelector(configViewOptionsSelector, (configViewOptions) =>
  configViewOptions
    .filter((option) => option.isVisible)
    .map((option) => ({ fieldName: option.fieldName, isCustomField: option.isCustomField }))
    .concat([
      {
        fieldName: 'title',
        isCustomField: false,
      },
      {
        fieldName: 'buttons',
        isCustomField: false,
      },
    ]),
);

const hasViewTypeChanged = (currentConfigView: ConfigurableView, savedConfigView: ConfigurableView) => {
  // this one will be the same name, but if the saved one is not default, then that determines the change.
  if (savedConfigView.isDefault) return false;
  // In kanban view, groupBy must exist. This handles the case when workspace has no custom fields to group by
  // in this case, it cannot be saved
  if (currentConfigView.viewType.name === ViewTypeName.kanban) {
    return !!currentConfigView.groupBy;
  }
  return true;
};
const hasSortingChanged = (currentConfigView: ConfigurableView, savedConfigView: ConfigurableView) => {
  return (
    currentConfigView.sortFields[0]?.name !== savedConfigView.sortFields[0]?.name ||
    currentConfigView.sortFields[0]?.direction !== savedConfigView.sortFields[0]?.direction
  );
};
const hasVisibilityChanged = (currentConfigView: ConfigurableView, savedConfigView: ConfigurableView) => {
  if (currentConfigView.visibleFields.length !== savedConfigView.visibleFields.length) return true;
  return currentConfigView.visibleFields.reduce((hasChanges, field) => {
    if (hasChanges) return true;
    return !savedConfigView.visibleFields.some(({ customFieldId, name }) =>
      customFieldId ? customFieldId === field.customFieldId : name === field.name,
    );
  }, false);
};
const hasKanbanGroupByChanged = (currentConfigView: ConfigurableView, savedConfigView: ConfigurableView) => {
  return currentConfigView.groupBy?.customFieldId !== savedConfigView.groupBy?.customFieldId;
};

/** exported for tests only */
export const hasKanbanVisibleColumnsChanged = (
  currentConfigView: ConfigurableView,
  savedConfigView: ConfigurableView,
) => {
  const currentVisibleColumns = currentConfigView.visibleColumns;
  const savedVisibleColumns = savedConfigView.visibleColumns;
  if (isUndefined(currentVisibleColumns) && isUndefined(savedVisibleColumns)) return false;
  return !isEqual(currentVisibleColumns, savedVisibleColumns);
};
export const currentConfigViewHasChangesSelector = createSelector(
  [currentConfigurableViewSelector, savedConfigurableViewsSelector],
  (currentConfigView, savedConfigViews) => {
    const savedConfigView = savedConfigViews.find((configView) => configView.id === currentConfigView?.id);
    if (!savedConfigView || savedConfigView.id.includes('boardless-view') || !currentConfigView) return false;
    return (
      hasViewTypeChanged(currentConfigView, savedConfigView) ||
      hasSortingChanged(currentConfigView, savedConfigView) ||
      hasVisibilityChanged(currentConfigView, savedConfigView) ||
      hasKanbanGroupByChanged(currentConfigView, savedConfigView) ||
      hasKanbanVisibleColumnsChanged(currentConfigView, savedConfigView)
    );
  },
);

export const currentKanbanGroupBySelector = createSelector(
  [currentConfigurableViewSelector, configurableViewFieldsSelector],
  (currentConfigView, configViewFields) => {
    const currentGroupBy = currentConfigView?.groupBy?.customFieldId;
    if (!currentGroupBy) return null;
    return configViewFields.find((field) => field.customFieldId === currentGroupBy) || null;
  },
);

export const currentKanbanGroupByCustomFieldIdSelector = createSelector(
  currentKanbanGroupBySelector,
  (currentGroupBy) => currentGroupBy?.customFieldId,
);

const kanbanColumnsSelector = createSelector(
  [currentConfigurableViewSelector],
  (currentConfigView) => currentConfigView?.visibleColumns,
);

export const visibleColumnIdsSelector = createSelector(
  kanbanColumnsSelector,
  (columns) =>
    columns?.map((column) => {
      if (column.type === VisibleColumnType.customFieldValue) {
        return column.customFieldValueId;
      }
      return VisibleColumnType.unassignedCustomFieldValue;
    }),
);

export const kanbanGroupByOptionsSelector = createSelector(configurableViewFieldsSelector, (viewFields) => {
  return viewFields.filter((field) => field.supports.includes(ViewFieldSupport.groupBy));
});

export const currentViewIdSelector = createSelector(
  [currentConfigurableViewSelector],
  (currentView) => currentView?.id,
);

// This is necessary for Cypress to mock methods, and must include all exported methods
module.exports = {
  currentConfigurableViewSelector,
  isKanbanViewSelector,
  visibleFieldsSelector,
  currentViewTypeNameSelector,
  savedConfigurableViewsSelector,
  availableViewTypeNamesSelector,
  currentSortFieldSelector,
  isSortFieldKanbanDefaultSelector,
  configViewOptionsSelector,
  visibleCustomFieldsViewOptionsSelector,
  visibleCustomFieldsLengthSelector,
  configViewVisiblityOptionsSelector,
  customFieldVisibilityOptionsSelector,
  visibleConfigViewOptionsSelector,
  sortingConfigViewOptionsSelector,
  currentSortFieldNameSelector,
  currentSortFieldDirectionSelector,
  isTitleAndMetadataVisibleSelector,
  activeTableColumnsSelector,
  currentConfigViewHasChangesSelector,
  currentKanbanGroupBySelector,
  currentKanbanGroupByCustomFieldIdSelector,
  visibleColumnIdsSelector,
  kanbanGroupByOptionsSelector,
  currentViewIdSelector,
  hasKanbanVisibleColumnsChanged,
};
