import { useField } from 'formik';

import { Input, InputProps } from '../Input';

export interface FormikInputProps extends Omit<InputProps, 'id' | 'ref' | 'tx'> {}

export const FormikInput = ({ name, onChange, ...restOfProps }: FormikInputProps) => {
  const [{ onChange: onFieldChange, ...field }, { touched, error }] = useField(name);

  return (
    <Input
      {...restOfProps}
      {...field}
      errorText={touched ? error : undefined}
      name={name}
      onChange={onChange || onFieldChange}
    />
  );
};
