import { createReducer } from '@reduxjs/toolkit';

import {
  queueUploadsAction,
  rejectUploadsAction,
  removeRejectedUploadAction,
  removeUploadsAction,
  removeUploadsForClipsAction,
  resetUploadsAction,
  setAllFilesUploaded,
  setLargeUploadCancellablesAction,
  setLargeUploadProgressAction,
  setLargeUploadUrlInfoAction,
  setSmallUploadCancellablesAction,
  setSmallUploadProgressAction,
  setUploadAbortedAction,
  setUploadCompletedAction,
  setUploadedPartInfo,
  setUploadFailedAction,
  setUploadImageLoadingAction,
  setUploadImagePreviewAction,
  setUploadingSpeedAction,
  setUploadPreparingAction,
  setUploadSkippedAction,
  setUploadsPausedAction,
  setUploadsQueuedAction,
  setUploadsResumedAction,
  setUploadUploadingAction,
} from './actions';
import { Upload, UploaderState, UploadStatus } from './types';

const getRejectedMessage = (message: string) => {
  switch (message) {
    case 'empty': {
      return 'File is empty';
    }
    case 'unsupported': {
      return 'File type not permitted';
    }
    case 'noExtension': {
      return 'File has no extension';
    }
    default: {
      return 'Unkown reason';
    }
  }
};

const initialState: UploaderState = {
  rejectedUploads: [],
  uploadsArray: [],
  uploadProgress: {},
  currentUploadingInfo: {
    startedAt: 0,
    uploadedBytes: 0,
    speed: null,
  },
};

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setUploadPreparingAction, (state, action) => {
      const uploadIndex = state.uploadsArray.findIndex((u) => u.id === action.payload.uploadId);

      if (uploadIndex >= 0) {
        state.uploadsArray[uploadIndex].status = UploadStatus.preparing;
      }
    })
    .addCase(setUploadUploadingAction, (state, action) => {
      if (!state.currentUploadingInfo.startedAt) {
        state.currentUploadingInfo.startedAt = new Date().getTime();
      }

      const uploadIndex = state.uploadsArray.findIndex((u) => u.id === action.payload.uploadId);
      if (uploadIndex >= 0) {
        state.uploadsArray[uploadIndex].status = UploadStatus.uploading;
      }
    })
    .addCase(setUploadsQueuedAction, (state, action) => {
      const uploadIndex = state.uploadsArray.findIndex((u) => u.id === action.payload.uploadId);
      if (uploadIndex >= 0) {
        state.uploadsArray[uploadIndex].status = UploadStatus.queued;
      }
    })
    .addCase(setUploadAbortedAction, (state, action) => {
      const uploadIndex = state.uploadsArray.findIndex((u) => u.id === action.payload.uploadId);
      if (uploadIndex >= 0) {
        state.uploadsArray[uploadIndex].status = UploadStatus.aborted;
        state.uploadsArray[uploadIndex].xhr = undefined;
      }
    })
    .addCase(setUploadFailedAction, (state, { payload: { uploadId } }) => {
      const uploadIndex = state.uploadsArray.findIndex((u) => u.id === uploadId);
      if (uploadIndex >= 0) {
        state.uploadsArray[uploadIndex].status = UploadStatus.failed;
        state.uploadsArray[uploadIndex].xhr = undefined;

        delete state.uploadProgress[uploadId];
        state.uploadsArray[uploadIndex].s3Info = { uploadUrlInfo: undefined, uploadedParts: [], totalBytesUploaded: 0 };
      }
    })
    .addCase(setUploadCompletedAction, (state, action) => {
      const uploadIndex = state.uploadsArray.findIndex((u) => u.id === action.payload.uploadId);
      if (uploadIndex >= 0) {
        state.uploadsArray[uploadIndex].status = UploadStatus.completed;
        state.uploadsArray[uploadIndex].completedAt = new Date().getTime();
        state.uploadsArray[uploadIndex].xhr = undefined;
      }
    })
    .addCase(setSmallUploadCancellablesAction, (state, action) => {
      const { uploadId, xhr, uploadUrlInfo } = action.payload;
      const uploadIndex = state.uploadsArray.findIndex((u) => u.id === uploadId);
      if (uploadIndex >= 0) {
        state.uploadsArray[uploadIndex].clipId = uploadUrlInfo ? uploadUrlInfo.id : '';
        // @ts-ignore it does not like WriteableDraft xhr being assigned this
        state.uploadsArray[uploadIndex].xhr = xhr;
        if (state.uploadsArray[uploadIndex].s3Info) {
          state.uploadsArray[uploadIndex].s3Info.uploadUrlInfo = uploadUrlInfo;
        } else {
          state.uploadsArray[uploadIndex].s3Info = {
            uploadUrlInfo: uploadUrlInfo,
          };
        }
      }
    })
    .addCase(setLargeUploadCancellablesAction, (state, action) => {
      const { uploadId, xhr, uploadUrlInfo } = action.payload;
      const uploadIndex = state.uploadsArray.findIndex((u) => u.id === uploadId);
      if (uploadIndex >= 0) {
        state.uploadsArray[uploadIndex].clipId = uploadUrlInfo ? uploadUrlInfo.id : '';
        // @ts-ignore it does not like WriteableDraft xhr being assigned this
        state.uploadsArray[uploadIndex].xhr = state.uploadsArray[uploadIndex].xhr
          ? { ...state.uploadsArray[uploadIndex].xhr, ...xhr }
          : { ...xhr };
        if (state.uploadsArray[uploadIndex].s3Info) {
          state.uploadsArray[uploadIndex].s3Info.uploadUrlInfo = uploadUrlInfo;
        } else {
          state.uploadsArray[uploadIndex].s3Info = {
            uploadUrlInfo: uploadUrlInfo,
          };
        }
      }
    })
    .addCase(setUploadImagePreviewAction, (state, action) => {
      const uploadIndex = state.uploadsArray.findIndex((u) => u.id === action.payload.uploadId);
      if (uploadIndex >= 0) {
        state.uploadsArray[uploadIndex].imageBase64 = action.payload.imageBase64;
        state.uploadsArray[uploadIndex].isImageLoading = false;
      }
    })
    .addCase(setUploadSkippedAction, (state, action) => {
      const uploadIndex = state.uploadsArray.findIndex((u) => u.id === action.payload.uploadId);
      if (uploadIndex >= 0) {
        state.uploadsArray[uploadIndex].status = UploadStatus.queued;
        state.uploadsArray[uploadIndex].xhr = undefined;
      }
    })
    .addCase(removeUploadsAction, (state, { payload: { uploadIds } }) => {
      state.uploadsArray = state.uploadsArray.filter((u) => !uploadIds.includes(u.id));
    })
    .addCase(rejectUploadsAction, (state, action) => {
      action.payload.files.forEach((file) => {
        state.rejectedUploads.push({
          id: file.name, // added for TS
          date: Date.now(), // added for TS.... may affect handling of removeRejectedUploadAction
          name: file.name,
          message: getRejectedMessage(file.reason),
          reason: file.reason,
          status: UploadStatus.rejected,
        });
      });
    })
    .addCase(removeRejectedUploadAction, (state, action) => {
      state.rejectedUploads = state.rejectedUploads.filter(
        ({ name, date }) =>
          !(action.payload.rejectedUpload.name === name && action.payload.rejectedUpload.date === date),
      );
    })
    .addCase(resetUploadsAction, (state) => {
      state.uploadsArray = [];
      state.rejectedUploads = [];
      state.uploadProgress = {};
      state.currentUploadingInfo = { ...initialState.currentUploadingInfo };
    })
    .addCase(queueUploadsAction, (state, action) => {
      // @ts-ignore it does not like the writable wrapper
      const uploadsArray: Upload[] = [...state.uploadsArray, ...action.payload.uploads];
      return {
        ...state,
        uploadsArray,
      };
    })

    .addCase(setSmallUploadProgressAction, (state, { payload: { uploadId, progress, abort } }) => {
      if (!state.uploadProgress[uploadId]) {
        state.uploadProgress[uploadId] = { progress, progressBeforePause: 0 };
        state.currentUploadingInfo.uploadedBytes += state.uploadProgress[uploadId].progress;
      } else if (progress >= state.uploadProgress[uploadId].progress) {
        const uploadDiff = progress - state.uploadProgress[uploadId].progress;
        state.uploadProgress[uploadId].progress = progress;

        state.currentUploadingInfo.uploadedBytes += uploadDiff;
      }
      state.uploadProgress[uploadId].abort = abort;
    })

    .addCase(setLargeUploadProgressAction, (state, { payload: { progress, partNumber, uploadId, abort } }) => {
      if (!state.uploadProgress[uploadId]) {
        state.uploadProgress[uploadId] = { progress: progress, progressBeforePause: 0 };
        state.currentUploadingInfo.uploadedBytes += state.uploadProgress[uploadId].progress;
      } else {
        const currentPartProgress = state.uploadProgress[uploadId].partsProgress?.[partNumber];
        if (!currentPartProgress || currentPartProgress < progress) {
          if (state.uploadProgress[uploadId].partsProgress) {
            state.uploadProgress[uploadId].partsProgress![partNumber] = progress;
          } else {
            state.uploadProgress[uploadId].partsProgress = { [partNumber]: progress };
          }
          const totalProgress = Object.keys(state.uploadProgress[uploadId].partsProgress!).reduce(
            (sum, partProgress) => {
              return sum + state.uploadProgress[uploadId].partsProgress![parseInt(partProgress)];
            },
            0,
          );
          const uploadDiff = totalProgress - state.uploadProgress[uploadId].progress;
          state.uploadProgress[uploadId].progress = totalProgress;

          state.currentUploadingInfo.uploadedBytes += uploadDiff;
        }
      }
      state.uploadProgress[uploadId].abort = abort;
    })
    .addCase(removeUploadsForClipsAction, (state, action) => {
      state.uploadsArray = state.uploadsArray.filter((upload) => !action.payload.clipIds.includes(upload.clipId));
    })
    .addCase(setUploadImageLoadingAction, (state, { payload: { uploadId, isImageLoading } }) => {
      const uploadIndex = state.uploadsArray.findIndex((u) => u.id === uploadId);
      if (uploadIndex >= 0) {
        state.uploadsArray[uploadIndex].isImageLoading = isImageLoading;
      }
    })
    .addCase(setAllFilesUploaded, (state) => {
      state.currentUploadingInfo = { ...initialState.currentUploadingInfo };
    })
    .addCase(setUploadingSpeedAction, (state, { payload: { speed } }) => {
      state.currentUploadingInfo.speed = speed;
    })
    .addCase(setUploadsPausedAction, (state, { payload: { uploadIds } }) => {
      uploadIds.forEach((uploadId) => {
        const uploadIndex = state.uploadsArray.findIndex((u) => u.id === uploadId);
        if (uploadIndex >= 0) {
          state.uploadsArray[uploadIndex].status = UploadStatus.paused;
          state.uploadsArray[uploadIndex].xhr = undefined;
          if (state.uploadProgress[uploadId]) {
            state.uploadProgress[uploadId].progressBeforePause = state.uploadProgress[uploadId].progress;
          }
        }
      });
    })
    .addCase(setUploadsResumedAction, (state, { payload: { uploadIds } }) => {
      uploadIds.forEach((uploadId) => {
        const uploadIndex = state.uploadsArray.findIndex((u) => u.id === uploadId);
        if (uploadIndex >= 0) {
          state.uploadsArray[uploadIndex].status = UploadStatus.queued;
        }
      });
    })
    .addCase(setUploadedPartInfo, (state, { payload: { uploadId, totalBytesUploaded, uploadedPart } }) => {
      const uploadIndex = state.uploadsArray.findIndex((u) => u.id === uploadId);
      if (uploadIndex >= 0) {
        state.uploadsArray[uploadIndex].s3Info.totalBytesUploaded = totalBytesUploaded;
        state.uploadsArray[uploadIndex].s3Info.uploadedParts
          ? state.uploadsArray[uploadIndex].s3Info.uploadedParts!.push(uploadedPart)
          : (state.uploadsArray[uploadIndex].s3Info.uploadedParts = [uploadedPart]);
      }
    })
    .addCase(setLargeUploadUrlInfoAction, (state, { payload: { uploadUrlInfo, uploadId } }) => {
      const uploadIndex = state.uploadsArray.findIndex((u) => u.id === uploadId);
      if (uploadIndex >= 0) {
        state.uploadsArray[uploadIndex].s3Info.uploadUrlInfo = uploadUrlInfo;
        if (uploadUrlInfo?.id) {
          state.uploadsArray[uploadIndex].clipId = uploadUrlInfo.id;
        }
      }
    });
});
